<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <div class="flex flex-col">

        <!-- Page Header -->
        <div class="flex justify-between items-center mb-6">
          <div>
            <h1 class="text-2xl sm:text-3xl lg:text-4xl">Buffer Management</h1>
          </div>
        </div>

        <!-- Balance Section -->
        <div class="mt-6 sm:mt-8 lg:mt-10">
          <div class="flex flex-col sm:flex-row justify-between border-b pb-3">
            <div>
              <h1 class="text-lg sm:text-xl lg:text-2xl font-bold mb-2 sm:mb-0">My Buffer Balance:</h1>
            </div>
            <div v-if="accounts.length > 0" class="mb-2 sm:mb-0">
              <h1 class="text-lg sm:text-xl lg:text-2xl font-bold">
                GMD {{ formatPrice(accounts[2].balance) }}
              </h1>
            </div>
          </div>
        </div>


        <!-- Form Section -->
        <div class="mt-6 sm:mt-8 lg:mt-10">
          <form class="w-full" @submit.prevent="showConfirmModal()">
            <div class="group">
              <label class="block text-gray-700 text-sm sm:text-base lg:text-lg font-normal mb-2" for="username">
                Select Account
              </label>
              <select v-model="form.account"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option selected disabled value="">Select an account from here</option>
                <option v-for="(account, i) in accounts" :key="i" :value="account" :disabled="account.id === 4">
                  {{ account.name }} (Current Balance: {{ account.balance }})
                </option>
              </select>
            </div>
            <div class="group my-4">
              <label class="block text-gray-700 text-sm sm:text-base lg:text-lg font-normal mb-2" for="amount">
                Amount
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="name" v-model="form.amount" type="number" :class="{ 'is-invalid': form.errors.has('amount') }"
                required placeholder="Enter amount" />
            </div>
            <div v-if="accounts[2].balance < form.amount" class="group my-4 text-red-400">
              Error
            </div>
            <div class="group">
              <button :disabled="accounts[2].balance < form.amount || isLoading"
                :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                class="px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 w-full disabled:opacity-50"
                @click="showConfirmModal()">
                <span v-if="isLoading">Loading...</span>
                <span v-else>Submit</span>
              </button>
            </div>
          </form>
          <!-- Confirmation Modal -->
          <div>
            <confimation v-show="isConfirmModalVisible" modalHeadline="Please confirm?" :isLoading="isLoading"
              :confirmMessage="`Are you sure you want to replenishment GMD${form.amount} to ${form.account.name} `"
              @confirmEvent="confirmRequest" @close="closeConfirmModal" />
          </div>
        </div>

        <!-- Table Section -->
        <div class="overflow-x-auto mt-10">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <!-- Table Header -->
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="
                      px-6
                      py-2
                      text-left text-sm
                      text-gray-900
                      tracking-wider
                    ">
                    #Account
                  </th>
                  <th scope="col" class="
                      
                      py-2
                      text-right text-sm
                      text-gray-900
                      tracking-wider
                    ">
                    Amount
                  </th>
                  <th scope="col" class="hidden px-3 py-2 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                    Replenish At
                  </th>
                  <th scope="col" class="hidden px-3 py-2 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                    Replenish By
                  </th>

                </tr>
              </thead>
              <!-- Table Body -->
              <tbody v-if="bufferTransactions" class="bg-white divide-y divide-gray-200">
                <tr v-for="(trans, i) in bufferTransactions.data" :key="i">
                  <td class="px-5 py-4 whitespace-nowrap text-sm">
                    <div class="flex items-center">
                      <b>{{ trans.account }}</b>
                    </div>
                    <div class="md:hidden text-xs text-gray-500">
                      {{ trans.created_at | moment("Do MMM, YYYY, h:mm:ss a") }}<br />
                      {{ trans.created_by }}
                    </div>
                  </td>
                  <td class="px-0 py-4 sm:px-0 lg:px-0 whitespace-nowrap text-right">
                    <div class="text-sm font-medium text-gray-900">
                      GMD {{ formatPrice(trans.amount) }}
                    </div>
                  </td>
                  <td class="hidden sm:table-cell px-3 py-4 whitespace-nowrap text-right">
                    {{ trans.created_at | moment("Do MMM, YYYY, h:mm:ss a") }}
                  </td>
                  <td class="hidden sm:table-cell px-3 py-4 whitespace-nowrap text-right">
                    {{ trans.created_by }}
                  </td>
                </tr>
                <!-- More rows... -->
              </tbody>
            </table>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
// import BranchSales from "../../components/Charts/BranchSales";
import Confimation from "../../components/Confimation.vue";

export default {
  middleware: "auth",
  layout: "default",
  components: {
    Confimation,
  },

  data: () => ({
    bufferTransactions: null,
    accounts: [],
    isLoading: false,
    isConfirmModalVisible: false,

    form: new Form({
      account: "",
      amount: "",
    }),
  }),

  created() {
    var _this = this;
    _this.loadData(1);
    _this.loadBalance();
  },

  methods: {
    total(param) {
      return param.reduce(function (total, item) {
        return total + item.balance_allocated;
      }, 0);
    },

    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },

    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        this.onSubmit();
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.post("/store/buffer_transaction");

        this.isLoading = false;

        this.loadData(1);

        this.form.account = "";
        this.form.amount = "";

        this.closeConfirmModal();
        // manage_floats
        // this.$router.push({ path: "/manage_floats" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    async loadBalance() {
      // console.log("LLLLLLLL");
      try {
        const { data } = await axios.get(`/accounts`);

        this.accounts = data.data;

        return true;
      } catch (e) {
        console.log("Error", e);
        return false;
      }
    },

    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      //buffer_transactions
      try {
        const { data } = await axios.get(
          `/buffer_transactions?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.bufferTransactions = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>